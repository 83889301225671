<template>
  <footer
    :class="{
      footer: true,
      '--show': !pagesTransition,
      '--hide': pagesTransition,
    }"
  >
    <div>
      <router-link to="/">159F</router-link>
    </div>
    <div class="footer__menu">
      <ul class="no-style">
        <li>
          <router-link to="/about">{{ loc.about }}</router-link>
        </li>
        <li>
          <router-link to="/artists">{{ loc.artists }}</router-link>
        </li>
        <!-- <li>
          <router-link to="/multimedia">Multimedia</router-link>
        </li> -->
        <li>
          <router-link to="/exhibitions">{{ loc.exhibitions }}</router-link>
        </li>
        <li>
          <router-link to="/fairs">{{ loc.fairs }}</router-link>
        </li>
        <li>
          <router-link to="/catalogue">{{ loc.catalogue }}</router-link>
        </li>
        <li>
          <router-link to="/contacts">{{ loc.contacts }}</router-link>
        </li>
      </ul>
    </div>
    <div class="footer__copy">
      <a target="_blank" href="https://madeinburo.com">(С) Made in Buro</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBlock",
  components: {},
  mounted() {},
  computed: {
    pagesTransition() {
      return this.$store.getters.getPagesTransition()
    },
    loc() {
      return this.$store.getters.getLoc()
    },
  },
}
</script>
