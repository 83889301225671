<template>
  <header
    :class="{
      header: true,
      '--show': !pagesTransition,
      '--hide': pagesTransition,
      '--with-menu': isMenuVisible,
      ['--for-' + routeName]: true,
      '--white-state': routeName == 'artist' && !scrolledDown,
    }"
  >
    <div class="header__logo">
      <router-link to="/" @click="hideMenu">159F</router-link>
    </div>
    <div class="header__title">
      <router-link to="/artists" v-if="routeName == 'artist'">
        {{ routeTitle }}
      </router-link>
      <span v-else>{{ routeTitle }}</span>
    </div>
    <div class="header__menu">
      <span class="_desk" @click="showMenu">{{ loc.menu }}</span>
      <span class="_mob-icon" @click="showMenu">
        <span></span>
      </span>
    </div>
    <div class="menu">
      <div class="menu__items">
        <ul class="no-style">
          <li>
            <router-link to="/about" @click="hideMenu">{{
              loc.about
            }}</router-link>
          </li>
          <li>
            <router-link to="/artists" @click="hideMenu">{{
              loc.artists
            }}</router-link>
          </li>
          <!-- <li>
            <router-link to="/multimedia" @click="hideMenu">
              Multimedia
            </router-link>
          </li> -->
          <li>
            <router-link to="/exhibitions" @click="hideMenu">
              {{ loc.exhibitions }}
            </router-link>
          </li>
          <li>
            <router-link to="/fairs" @click="hideMenu">{{
              loc.fairs
            }}</router-link>
          </li>
          <li>
            <router-link to="/catalogue" @click="hideMenu">
              {{ loc.catalogue }}
            </router-link>
          </li>
          <li>
            <router-link to="/contacts" @click="hideMenu">{{
              loc.contacts
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="menu__footer">
        <div class="menu__footer__langs">
          <span @click="changeLang('ru')">Ru</span>
          <span @click="changeLang('en')">En</span>
        </div>
        <div class="menu__footer__address">
          {{ params.address }}
        </div>
        <div class="menu__footer__inst">
          <a :href="params.instUrl">Instagram</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderBlock",
  components: {},
  data() {
    return {
      isMenuVisible: false,
      scrolledDown: false,

      pageTitles: {
        home: "Gallery",
        about: "About",
        artists: "Artists",
        artist: "Artists",
        multimedia: "Multimedia",
        exhibitions: "Exhibitions",
        exhibition: "Exhibitions",
        fairs: "Fairs",
        fair: "Fairs",
        catalogue: "Catalogue",
        contacts: "Contacts",
      },
    }
  },
  props: {
    title: String,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll)
  },
  computed: {
    params() {
      return this.$store.getters.getParams()
    },
    loc() {
      return this.$store.getters.getLoc()
    },
    routeTitle() {
      return this.pageTitles[this.$route.name]
    },
    routeName() {
      return this.$route.name
    },
    pagesTransition() {
      return this.$store.getters.getPagesTransition()
    },
  },
  methods: {
    changeLang(lang) {
      this.$store.dispatch("setLang", lang)
      localStorage.setItem("159f_lang", lang)
      window.location.reload()
    },
    handleScroll() {
      if (window.innerWidth <= 780) {
        if (window.scrollY > window.innerHeight) {
          this.scrolledDown = true
        } else {
          this.scrolledDown = false
        }
      }
    },
    showMenu() {
      this.isMenuVisible = !this.isMenuVisible
    },
    hideMenu() {
      this.isMenuVisible = false
    },
  },
}
</script>
