import { createStore } from "vuex"

export default createStore({
  state: {
    params: {
      address: "Moscow, Russia, Kozihensky Pereulok 27",
      instUrl: "https://instagram.com",
      lang: "en",
    },
    loc: {
      ru: {
        about: "О нас",
        artists: "Художники",
        exhibitions: "Выставки",
        fairs: "Ярмарки",
        catalogue: "Каталог",
        contacts: "Контакты",
        menu: "Меню",
        cur_exh: "ТЕКУЩАЯ ВЫСТАВКА",
        read_more: "Читать",
      },
      en: {
        about: "About",
        artists: "Artists",
        exhibitions: "Exhibitions",
        fairs: "Fairs",
        catalogue: "Catalogue",
        contacts: "Contacts",
        menu: "MENU",
        cur_exh: "CURRENT EXHIBITION",
        read_more: "Read more",
      },
    },
    exhibitions: [],
    fairs: [],
    artists2: [],
    artists: [
      {
        id: 1,
        name: "Eugeniy Malishev",
        url: "evgeniy-malishev",
        folder: "paints",
        startPhoto: "Start.jpg",
        avatar: "cover.png",
        paints: [
          {
            id: 1,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "paint-0.jpg",
          },
          {
            id: 2,
            name: "Kids",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "paint-1.jpg",
          },
          {
            id: 3,
            name: "Figures 1",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "paint-2.jpg",
          },
          {
            id: 4,
            name: "Figures 2",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "paint-3.jpg",
          },
          {
            id: 5,
            name: "Beach",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "paint-4.jpg",
          },
          {
            id: 6,
            name: "Jump",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "paint-5.jpg",
          },
          {
            id: 7,
            name: "Flowers",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "paint-6.jpg",
          },
          {
            id: 8,
            name: "Flowers 2",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "paint-7.jpg",
          },
          {
            id: 9,
            name: "Flowers 3",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "paint-8.jpg",
          },
        ],
        exhibitions: [
          {
            id: 1,
            name: "The Observer's Affect, The 6th Moscow International Biennale for Young Art, CCA Winzavod, curator Arseniy Sergeev",
            year: 2021,
          },
          {
            id: 2,
            name: "New Slogans, hse art gallery, curator Alexander Shaburov",
            year: 2021,
          },
          {
            name: "Copied with a Lot of Reservations project, The Vyksa Artist-in-Residence, Vyksa",
            year: 2021,
          },
          {
            name: "Ray marching, volumes convincingly lit, material set to unlit, subsurface scattering, NPC under IBL, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
          {
            name: "The Speech of the Transparency Dictat, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
          {
            name: "Copied with a Lot of Reservations project, The Vyksa Artist-in-Residence, Vyksa",
            year: 2021,
          },
          {
            name: "The Speech of the Transparency Dictat, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
          {
            name: "Ray marching, volumes convincingly lit, material set to unlit, subsurface scattering, NPC under IBL, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
          {
            name: "The Speech of the Transparency Dictat, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
          {
            name: "Copied with a Lot of Reservations project, The Vyksa Artist-in-Residence, Vyksa",
            year: 2021,
          },
          {
            name: "Ray marching, volumes convincingly lit, material set to unlit, subsurface scattering, NPC under IBL, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
          {
            name: "Copied with a Lot of Reservations project, The Vyksa Artist-in-Residence, Vyksa",
            year: 2021,
          },
          {
            name: "The Speech of the Transparency Dictat, Triangle Gallery, Moscow, Russia",
            year: 2021,
          },
        ],
        interview: {
          cover: "artist-interview.jpg",
          videoCode: "",
        },
      },
      {
        id: 2,
        name: "Aleksey Vasiliev",
        url: "artist-2",
        folder: "paints-2",
        startPhoto: "",
        paints: [
          {
            id: 1,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "photo_2023-12-08_17-22-55.jpg",
          },
          {
            id: 2,
            name: "Kids",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "photo_2023-12-08_17-23-00.jpg",
          },
          {
            id: 3,
            name: "Kids",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "photo_2023-12-08_17-23-03.jpg",
          },
          {
            id: 4,
            name: "Kids",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "photo_2023-12-08_17-23-07.jpg",
          },
          {
            id: 5,
            name: "Kids",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "photo_2023-12-08_17-23-12.jpg",
          },
          {
            id: 6,
            name: "Kids",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "photo_2023-12-08_17-23-16.jpg",
          },
        ],
        interview: {
          cover: "",
          videoCode: "",
        },
      },
      {
        id: 3,
        name: "MOMA",
        url: "artist-3",
        startPhoto: "",
        paints: [],
        interview: {
          cover: "",
          videoCode: "",
        },
      },
      {
        id: 4,
        name: "Sasha Svyatoy",
        url: "artist-4",
        startPhoto: "",
        paints: [],
        interview: {
          cover: "",
          video_code: "",
        },
      },
      {
        id: 5,
        name: "Margarita Varakina",
        url: "margarita-varakina",
        startPhoto: "Start.jpg",
        avatar: "cover.jpg",
        paints: [
          {
            id: 1,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "1.jpg",
          },
          {
            id: 2,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "Emergency _ 150 _ 100 см холст, акрил, 2022.jpg",
          },
          {
            id: 3,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "palm_120_160_холст,_акрил,_пастель,_маркер,_2023.jpg",
          },
          {
            id: 4,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "Password_  120 _ 160 см холст, акрил, 2022.jpg",
          },
          {
            id: 5,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "Render fragment1 _ 50 _ 60 см холст, акрил, 2023.jpg",
          },
          {
            id: 6,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "Render fragment2 _ 50 _ 60 см холст, акрил, 2023.jpg",
          },
          {
            id: 7,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "Render_standards_1_140_180_см_холст,_акрил,_2022.jpg",
          },
          {
            id: 8,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "Render_standards_2_140_180_см_холст,_акрил,_2022.jpg",
          },
          {
            id: 9,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "Render_standards_3_140_180_см_холст,_акрил,_2022.jpg",
          },
          {
            id: 10,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "h",
            fileName: "Sight _ 150 _ 100 см холст, акрил, 2022.jpg",
          },
          {
            id: 11,
            name: "Waves",
            params: "2020, Argalit, acril, 100x150cm",
            price: 300000,
            exh: 1,
            or: "v",
            fileName: "Sunglasses_  120 _ 160 см холст, акрил, 2022.jpg",
          },
        ],
        interview: {
          cover: "artist-interview.jpg",
          videoCode: "",
        },
        exhibitions: [],
      },
      {
        id: 6,
        name: "Ivan Arkhipov",
        url: "artist-6",
        startPhoto: "",
        paints: [],
        interview: {
          cover: "",
          videoCode: "",
        },
      },
    ],
    pages: {},
    pagesTransition: false,
  },
  getters: {
    getExhibitions: (state) => () => {
      return state.exhibitions
    },
    getFairs: (state) => () => {
      return state.fairs
    },
    getArtists: (state) => (all) => {
      return all
        ? state.artists2
        : state.artists2.filter((item) => {
            return item.visible
          })
    },
    getParams: (state) => () => {
      return state.params
    },
    getPages: (state) => () => {
      return state.pages
    },
    getLoc: (state) => () => {
      return state.loc[state.params.lang]
    },
    getLang: (state) => () => {
      return state.params.lang
    },
    getPagesTransition: (state) => () => {
      return state.pagesTransition
    },
    getExhibitionById: (state) => (id) => {
      return state.exhibitions.find((item) => {
        return item.id == id
      })
    },
    getFairById: (state) => (id) => {
      return state.fairs.find((item) => {
        return item.id == id
      })
    },
    getArtistById: (state) => (id) => {
      return state.artists2.find((item) => {
        return item.id == id
      })
    },
    getArtistByUrl: (state) => (url) => {
      return state.artists2.find((item) => {
        return item.url == url
      })
    },
    getCatalogue: (state) => (withShuffle) => {
      let _paints = []
      state.artists2.forEach((artist) => {
        artist.paints.forEach((painting) => {
          painting.artist = artist.name
          painting.artistId = artist.id
          painting.folder = artist.folder
          _paints.push(painting)
        })
        // _paints = _paints.concat(artist.paints)
      })
      if (withShuffle) {
        _paints = _paints
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
        return _paints
      } else return _paints
    },
  },
  mutations: {
    SET_EXHIBITIONS(state, exh) {
      state.exhibitions = exh
    },
    SET_FAIRS(state, f) {
      state.fairs = f
    },
    SET_ARTISTS(state, a) {
      state.artists2 = a
    },
    SET_PARAMS(state, p) {
      state.params = p
    },
    SET_PAGES(state, p) {
      state.pages = p
    },
    SET_LANG(state, p) {
      state.params.lang = p
    },
    SET_TRANSITION(state, val) {
      state.pagesTransition = val
    },
  },
  actions: {
    setExhibitions({ commit }, newValue) {
      commit("SET_EXHIBITIONS", newValue)
    },
    setFairs({ commit }, newValue) {
      commit("SET_FAIRS", newValue)
    },
    setArtists({ commit }, newValue) {
      commit("SET_ARTISTS", newValue)
    },
    setParams({ commit }, newValue) {
      commit("SET_PARAMS", newValue)
    },
    setPages({ commit }, newValue) {
      commit("SET_PAGES", newValue)
    },
    setLang({ commit }, newValue) {
      commit("SET_LANG", newValue)
    },
    setPagesTransition({ commit }, newValue) {
      commit("SET_TRANSITION", newValue)
    },
  },
  modules: {},
})
